.vertical-center {
    text-align: center;
    vertical-align: middle !important;
}

.pl-0 {
    padding-left: 0;
}

.pointer {
    cursor: pointer;
}
